<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('users.client.update_btn')"
        :paths="[{title:$t('users.client.title'),link:'users-clients'}]"
    />
    <section class="wameed-dashboard-page-content_body products_create">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="wameed-card">
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-med-20 text-font-main mb-0">
                {{ $t('users.client.info') }}
              </h5>
            </b-card-header>
            <wameed-form @onSubmit="submit">
              <div slot-scope="{ invalid }">
                <b-card-body
                    class="position-relative justify-content-between px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <text-input
                          id="name"
                          v-model="form.name"
                          :label="$t('users.client.table.name')"
                          rules="required"
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <wameed-dropdown
                          v-model="form.account_type_obj"
                          :label="$t('users.client.table.account_type')"
                          :placeholder="$t('users.client.table.account_type')"
                          variant="disable"
                          :items="getAccountTypes"
                          class="wameed_dropdown"
                          title="name"
                          rules="required"
                          :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols="12" md="4">
                      <TextInput
                          disabled="disabled"
                          v-model="form.phone"
                          field-classes="w-100"
                          rules="required"
                          :label="$t('form.phone.label')"
                          :placeholder="$t('form.text.placeholder')"
                          type="number"
                      />
                    </b-col>

                    <b-col cols="12" md="4">
                      <TextInput
                          disabled="disabled"
                          v-model="form.email"
                          field-classes="w-100"
                          :label="$t('form.email.label')"
                          :placeholder="$t('form.text.placeholder')"
                          type="email"
                      />
                    </b-col>



                  </div>


                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="$t('users.client.update_btn')"
                          :disabled="invalid"
                          type="submit"
                          variant="main"
                      />
                    </div>

                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-font-secondary rounded-10 "
                          :title="$t('common.cancel')"
                          type="button"
                          variant="gray"
                          @onClick="goBack"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {WameedBtn, WameedDropdown, WameedForm, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm'
import {accountType} from "@/enums/userType.enum";

export default {
  components: {
    TextInput,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader,
  },
  data() {
    return {
      form: {
        name: '',
        account_type_obj: '',
        phone: '',
      },
      getAccountTypes: accountType,

    };
  },
  computed: {
    ...mapGetters({
      userDetail: 'admin/users/detail/getUserInfo',
    }),

  },
  async beforeCreate() {
    await this.$store.dispatch('admin/users/getAccountTypes');
    if (this.$route.name === 'users-clients-update' && this.$route.params.id) {
      await this.$store.dispatch('admin/users/detail/info', {
        id: this.$route.params.id,
        type: 'clients'
      }).then(() => {

        this.form ={
          name:this.userDetail.name,
          phone:this.userDetail.phone,
          email:this.userDetail.email,
        };
        this.form.account_type_obj = this.getAccountTypes.find((item) => item.id === this.userDetail.account_type);
      });

    } else {
      await this.$store.dispatch("admin/users/unsetDetail");
    }
  },


  methods: {


    goBack() {
      this.$router.push({name: 'users-clients'});
    },

    submit() {

      this.form.account_type = this.form.account_type_obj.id;
      this.form.type = 'clients'

      if (this.$route.name === 'users-clients-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        this.$store.dispatch('admin/users/update', this.form);
      } else {
        this.$store.dispatch('admin/users/create', this.form);
      }

    },
  }
  ,
  async mounted() {


  }
}
;
</script>
